.single_product_img {
    min-height: 500px;
    display: flex;
    justify-content: center;
}
.product_img {
    min-height: 355px !important;
    max-height: 355px !important;
    height: 355px !important;
    display: flex !important;
    justify-content: center;
}
.single_product_img img,
.product_img img {
    display: block;
    height: auto;
    align-self: center;
    width: 100%;
}
.color_view {
    display: flex;
}
.color_code {
    width: 45px;
    height: 45px;
    margin-inline: 2.5px;
    border-radius: 1100%;
}
.capacity_list {
    display: flex;
}
.capacity_list div {
    margin: 10px;
}
.errorMessage {
    margin-top: 5px;
    color: #e63d40;
    margin-bottom: 10px;
}
.lang_menu {
    background: transparent;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
}

.dorpdown_header_menu {
    position: absolute;
    z-index: 999999;
    opacity: 10;
    background: #f37521;
    border: 1px solid;
    padding: 10px 0px;
    border-radius: 10px;
    margin-top: 5px;
    cursor: pointer;
}

.dorpdown_header_menu a {
    margin-bottom: 5px !important;
}

.menu_link select option {
    background: var(--brown);
}

.navbar-brand img {
    width: 90px;
    height: 90px;
}

.SloganLogo {
    width: 300px;
}

@media only screen and (max-width: 767px) {
    .insta_list {
        display: flex;
        min-height: 255px !important;
        max-height: 255px !important;
        height: 255px !important;
        justify-content: center;
    }
    .steps .box {
        max-height: 150px;
    }
    .dorpdown_header_menu {
        position: relative;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 991px) {
    .navbar .container-fluid {
        display: flex;
        justify-content: space-between;
        padding-block: 10px;
    }

    .mobile_logo img {
        width: 75px !important;
        height: 48.7013px !important;
    }
    
    .SloganLogo {
        width: 180px;
        background: #f78f20;
    }

    .display picture { overflow: hidden; }
}

/* Container for the floating buttons */
.floating-buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000; /* Ensure the buttons are on top of other content */
  }
  
  /* Style for each floating button */
  .floating-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #007bff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 24px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
  }
  
  /* Hover effect */
  .floating-button:hover {
    background-color: #0056b3;
  }
  
  /* Optional: Different background colors for each button */
  .floating-button.email {
    background-color: #28a745;
  }
  
  .floating-button.email:hover {
    background-color: #218838;
  }
  
  .floating-button.phone {
    background-color: #17a2b8;
  }
  
  .floating-button.phone:hover {
    background-color: #117a8b;
  }
  
  .floating-button.whatsapp {
    background-color: #25d366;
  }
  
  .floating-button.whatsapp:hover {
    background-color: #1ebe57;
  }
  